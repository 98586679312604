
import Vue from 'vue';
import { RawLocation } from 'vue-router';
import HeaderLoader from '@/components/header/HeaderLoader.vue';
import AuthUser from '@/modules/@common/models/auth-user';
import AnalyticsService from '@/plugins/analytics-service';
import MainNavigation from '@/components/header/MainNavigation.vue';
import { NavigationSection } from '@localazy/directus-service';
import MainNavigationService from '@/modules/navigation/services/main-navigation-service';
import NavigationStateService from '@/modules/navigation/services/navigation-state-service';
import getLoginQuery from '@/functions/auth/get-login-query';
import { breakpoints, LocDropdown } from '@localazy/components';

interface IHeader {
  isAuthenticated: boolean;
  mainNavigation: NavigationSection[];
}

export default Vue.extend({
  components: {
    LocIcon: () => import('@localazy/components').then(m => m.LocIcon),
    LocUserAvatar: () => import('@localazy/components').then(m => m.LocUserAvatar),
    SvgLogo: () => import('@/assets/svg/logos/SvgLogo.vue'),
    SvgLogoSmall: () => import('@/assets/svg/logos/SvgLogoSmall.vue'),
    HeaderLoader,
    MainNavigation,
    LocDropdown,
    LoginButton: () => import('@/modules/navigation/components/LoginButton.vue'),
    UserMenu: () => import('~/modules/navigation/components/UserMenu.vue')
  },

  data (): IHeader {
    return {
      isAuthenticated: false,
      mainNavigation: []
    };
  },
  computed: {
    isMobileMenuOpen(): boolean {
      if (process.client) {
        return NavigationStateService.getInstance().vueInstance.isMobileMenuOpen;
      }
      return false;
    },
    registerLink (): RawLocation {
      return { path: '/register', query: getLoginQuery(this.$route) };
    },
    isLoggedIn (): boolean {
      if (this.authUser) {
        return this.authUser.result && this.isAuthenticated;
      }
      return false;
    },
    isSectionBasedArticle (): boolean {
      return this.$route.path.includes('/docs/') || this.$route.path.includes('/community/');
    },
    authUser (): AuthUser | null {
      return this.$store.state.authUser;
    },
    isExtraLargeScreen(): boolean {
      return process.client && breakpoints.xl;
    },
    userLink (): String {
      if (this.authUser?.result && this.authUser.slug) {
        return `/u/${this.authUser.slug}`;
      }
      return '';
    }
  },
  async fetch() {
    this.mainNavigation = await MainNavigationService.fetchMainNavigation();
  },
  mounted () {
    this.isAuthenticated = this.authUser !== null;
  },
  methods: {
    onOpenUserMenu () {
      if (this.isMobileMenuOpen) {
        NavigationStateService.getInstance().closeMobileMenu();
      }
    },
    toggleMobileMenu () {
      if (process.client) {
        NavigationStateService.getInstance().toggleMobileMenu();
      }
    },
    toggleArticleSubsectionMenu () {
      this.$store.commit('CHANGE_ARTICLE_SUBSECTION_MENU_STATE', !this.$store.state.isArticleSubsectionMenuOpen);
    },
    onLogout() {
      AnalyticsService.reset();
    }
  }
});
